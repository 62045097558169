import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
//import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

import DirectionsIcon from "@material-ui/icons/Directions";
import MyLocationIcon from "@material-ui/icons/MyLocation";
import AddLocationIcon from "@material-ui/icons/AddLocation";
import LayersClearIcon from "@material-ui/icons/LayersClear";
import MapIcon from "@material-ui/icons/Map";
import ClearIcon from "@material-ui/icons/Clear";

import red from "@material-ui/core/colors/red";

// leaflet
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import L, { Marker } from "leaflet";
import "leaflet-draw";

import {
  MAP_PARAMS,
  BASE_LAYER,
  setOsmSingleArea
  // GEOJSON_STYLE,
  // GEOJSON_MARKER_POINT,
  // tableFeature
} from "../lib/utils";

import {
  postInfoParameterAreas,
  deleteInfoParameterAreas,
  getOsmFromLatLon
} from "../api/api.js";

import {
  NOMINATIM_URL,
  NOMINATIM_LOOKUP,
  NOMINATIM_API_SEARCH,
  NOMINATIM_API_REVERSE
} from "../api/osm";

const ICON = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow
});

const styles = theme => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: 400
  },
  input: {
    marginLeft: 8,
    flex: 1
  },
  iconButton: {
    padding: 10
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4
  },
  mapContainer: {
    width: "100%",
    zIndex: 1
  }
});

Marker.prototype.options.icon = ICON;

class MapLeaflet extends Component {
  state = { osm: [], nominatim: "", results: [] };
  componentDidMount() {
    this._createMap();
  }

  componentWillUnmount() {
    this.map = null;
  }

  handleNominatim = event => {
    const nominatim = event.target.value;
    this.setState({ nominatim });
    nominatim.length < 1 && this.setState({ results: [] });
  };

  handleSearch = _ => {
    if (this.state.nominatim.length > 3) {
      const urlNominatim = `${NOMINATIM_URL}${NOMINATIM_API_SEARCH}${
        this.state.nominatim
      }`;
      fetch(urlNominatim)
        .then(res => res.json())
        .then(json => {
          this.setState({ results: json });
        })
        .catch(err => {
          return { error: err.message };
        });
    }
  };

  render() {
    const { classes, mapConfig } = this.props;
    // console.log(this.state.osm);
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div
            id={`map-params-${this.props.id}`}
            className={classes.mapContainer}
            style={{ height: mapConfig.height }}
          />
        </Grid>
        <Grid item xs={6}>
          <Paper className={classes.root}>
            <InputBase
              className={classes.input}
              placeholder="Search"
              value={this.state.nominatim}
              onChange={this.handleNominatim}
            />
            <IconButton
              disabled={this.state.nominatim.length > 0 ? false : true}
              className={classes.iconButton}
              aria-label="Directions"
              onClick={() => this.setState({ nominatim: "", results: [] })}
            >
              <ClearIcon />
            </IconButton>
            <Divider className={classes.divider} />
            <IconButton
              color="primary"
              className={classes.iconButton}
              aria-label="Directions"
              onClick={this.handleSearch}
            >
              <DirectionsIcon />
            </IconButton>
          </Paper>
          {this.state.results.length > 0 && (
            <Paper>
              <List component="nav">
                {this.state.results.map((r, idx) => (
                  <ListItem button key={`res-${idx}`}>
                    <ListItemText secondary={r.display_name} />
                    <Tooltip title="Zoom to map" placement="top">
                      <ListItemIcon onClick={() => this.gotoNominatim(r)}>
                        <MyLocationIcon color="primary" />
                      </ListItemIcon>
                    </Tooltip>
                    <Tooltip title="Add" placement="top">
                      <ListItemIcon>
                        <AddLocationIcon
                          onClick={() => this.handleAddListItem(r)}
                          color="secondary"
                        />
                      </ListItemIcon>
                    </Tooltip>
                  </ListItem>
                ))}
              </List>
            </Paper>
          )}
        </Grid>
        <Grid item xs={6}>
          {this.state.osm.length > 0 && (
            <Paper>
              <List component="nav">
                {this.state.osm.map((o, idx) => (
                  <ListItem button key={`osm-${idx}`}>
                    <ListItemText secondary={o.item.display_name} />
                    {o.layer !== null && (
                      <Tooltip title="Zoom to map" placement="top">
                        {o.item.lat !== null ? (
                          <ListItemIcon
                            onClick={() => this.gotoNominatim(o.item)}
                          >
                            <MyLocationIcon color="primary" />
                          </ListItemIcon>
                        ) : (
                          <ListItemIcon
                            onClick={() => this.gotoNominatim(o.item)}
                          >
                            <MapIcon color="secondary" />
                          </ListItemIcon>
                        )}
                      </Tooltip>
                    )}
                    <Tooltip title="delete" placement="top">
                      <ListItemIcon>
                        <LayersClearIcon
                          onClick={() => this.handleOsmRemove(idx)}
                          style={{ color: red[500] }}
                        />
                      </ListItemIcon>
                    </Tooltip>
                  </ListItem>
                ))}
              </List>
            </Paper>
          )}
        </Grid>
      </Grid>
    );
  }

  addAreaOsm = async (layer, item) => {
    const osmdb = setOsmSingleArea(item);
    if (this.props.actions.hasOwnProperty("component")) {
      item["display_name"] = osmdb.description
      item["osm_id"] = osmdb.osm_id
      item["osm_address"] = osmdb.osm_address
      const osminfo = [...this.state.osm, { layer, item, id: 0 }];
      this.setState({ osm: osminfo });
      this.props.actions.updateOsmPlace(osminfo);
    } else {
      const osmState = await getOsmFromLatLon(this.props.actions.paramsData.user, item)
      osmdb.osm_address['state'] = osmState.osm_address.state
      const resParams = await postInfoParameterAreas(
        this.props.actions.paramsData.user,
        this.props.actions.paramsData.identifier,
        this.props.actions.paramsData.idParams,
        osmdb
      );
      if (resParams.hasOwnProperty("id")) {
        const osm = { layer, item, id: resParams.id };
        this.setState({ osm: [...this.state.osm, osm] });
        this.props.actions.updateInfo();
      }
    }
  };

  deleteAreaOsm = async idArea => {
    const resParams = await deleteInfoParameterAreas(
      this.props.actions.paramsData.user,
      this.props.actions.paramsData.identifier,
      this.props.actions.paramsData.idParams,
      idArea
    );
    console.log("deleted ", resParams);
    this.props.actions.updateInfo();
  };

  handleOsmRemove = idx => {
    let osm = [...this.state.osm];
    const item = osm.splice(idx, 1);
    this.setState({ osm });
    if (item[0].layer !== null) this.map.removeLayer(item[0].layer);
    if (this.props.actions.hasOwnProperty("component")) {
      this.props.actions.updateOsmPlace(osm);
    } else {
      if (item[0].id > 0) this.deleteAreaOsm(item[0].id);
    }
  };

  gotoNominatim = item => {
    this.map.flyTo([item.lat, item.lon], 10);
  };

  addMarker = item => {
    try {
      return L.marker([item.lat, item.lon], {
        icon: ICON
      })
        .bindPopup(item.display_name)
        .addTo(this.map);
    } catch (error) {
      console.log(item);
      return null;
    }
  };

  handleAddListItemNoGeom = feature => {
    const osm = {
      layer: null,
      item: { display_name: feature.properties.description },
      id: feature.id
    };
    this.setState({ osm: [...this.state.osm, osm] });
  };

  handleAddListItem = item => {
    const layer = this.addMarker(item);
    if (layer) {
      this.addAreaOsm(layer, item);
    }
  };

  handleAddAreaItem = (item, id = 0) => {
    let layer = null;
    if (item.geometry) {
      layer = L.geoJSON(item.geometry)
        .bindPopup(item.display_name)
        .addTo(this.map);
    } else {
      layer = this.addMarker(item);
    }

    if (layer) {
      if (id < 1) {
        console.log("devo fare la post!!");
        // this.props.actions.updateOsmPlace([...this.state.osm, osm], {item:item});
      }
      const osm = { layer, item, id: id };
      this.setState({ osm: [...this.state.osm, osm] });
    }
  };

  handleLatLng = coord => {
    fetch(
      `${NOMINATIM_URL}${NOMINATIM_API_REVERSE}lat=${coord.lat}&lon=${
        coord.lng
      }`
    )
      .then(res => res.json())
      .then(item => {
        const layer = this.addMarker(item);
        if (layer) {
          this.addAreaOsm(layer, item);
        }
      })
      .catch(err => {
        return { error: err.message };
      });
  };

  _createMap = _ => {
    const map = L.map(`map-params-${this.props.id}`, {
      zoomControl: true
    }).setView(MAP_PARAMS.center, this.props.mapConfig.zoom);
    L.tileLayer(BASE_LAYER.url, {
      maxZoom: BASE_LAYER.maxZoom,
      attribution: ""
    }).addTo(map);

    /*
    map.on("click", e => {
      if (!this.state.isVisbleDrawToolbar) this.handleLatLng(e.latlng);
    });
    */

    const drawnItems = new L.FeatureGroup();
    map.addLayer(drawnItems);
    const drawControl = new L.Control.Draw({
      draw: {
        polyline: false,
        marker: { icon: Marker.prototype.options.icon },
        circlemarker: false
      },
      edit: {
        featureGroup: drawnItems,
        edit: false,
        remove: false
      }
    });

    map.addControl(drawControl);

    map.on(L.Draw.Event.CREATED, e => {
      const type = e.layerType;
      const layer = e.layer;
      const geojson = layer.toGeoJSON();
      if (type === "marker") {
        const coord = {
          lat: geojson.geometry.coordinates[1],
          lng: geojson.geometry.coordinates[0]
        };
        this.handleLatLng(coord);
      } else {
        try {
          const centroid =
            type !== "circle"
              ? layer.getBounds().getCenter()
              : layer.getLatLng();

          fetch(
            `${NOMINATIM_URL}${NOMINATIM_API_REVERSE}lat=${centroid.lat}&lon=${
              centroid.lng
            }`
          )
            .then(res => res.json())
            .then(item => {
              if (!item.hasOwnProperty("error")) {
                const geometry = geojson.geometry;
                item["geom"] = {
                  type: "GeometryCollection",
                  geometries: [geometry]
                };
                this.addAreaOsm(layer, item);
                drawnItems.addLayer(layer);
              } else {
                alert(JSON.stringify(item));
              }
            });
        } catch (err) {
          console.log("errore..");
          console.log(err);
        }
      }
    });

    const geoJson = this.props.areas;
    if (geoJson !== null) {
      if (geoJson.hasOwnProperty("features")) {
        geoJson.features.forEach(feature => {
          if (feature.properties.osm_id.length > 3) {
            const urlNominatim = `${NOMINATIM_URL}${NOMINATIM_LOOKUP}osm_ids=${
              feature.properties.osm_id
            }`;
            fetch(urlNominatim)
              .then(res => res.json())
              .then(json => {
                if (json.length === 1) {
                  const item = json[0];
                  item["geometry"] = feature.geometry;
                  this.handleAddAreaItem(item, feature.id);
                } else {
                  this.handleAddListItemNoGeom(feature);
                }
              })
              .catch(err => {
                return { error: err.message };
              });
          }
        });
      }
    }
    this.map = map;
    // this.drawControl = drawControl;
  };
}

function MapParamTable({ classes, id, areas, mapConfig, actions }) {
  return (
    <div>
      <MapLeaflet
        id={id}
        areas={areas}
        classes={classes}
        mapConfig={mapConfig}
        actions={actions}
      />
    </div>
  );
}

MapParamTable.propTypes = {
  id: PropTypes.string.isRequired,
  mapConfig: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  areas: PropTypes.object
};

export default withStyles(styles)(MapParamTable);
